<template>


  <v-row justify="center">
  
  

    

      <!-- IMPRIMIR RECIBO -->
      <v-dialog
        v-model="dialog_print"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >


        <!-- PAINEL TOPO AZUL BOTOES -->
        <v-card
         elevation="0"
        >
        
  
        <!-- {{ dialog_receber }} -->
        
        <!-- receber_de_cpf : {{ receber_de_cpf }} <br/> -->
        <!-- obj_dados_paciente:{{ obj_dados_paciente }} <br/><br/> -->
        <!-- obj_dados_empresa:{{ obj_dados_empresa }} <br/><br/> -->
        <!-- obj_doc_selecionado:{{ obj_doc_selecionado }} <br/><br/> -->
        <!-- dados:{{ dados }} <br/><br/> -->
        <!-- obj_dados_profissional: {{ obj_dados_profissional }} <br/> -->
        
        <!-- {{ urlLogo }} -->




        <v-dialog
        v-model="dialog_receber"
        max-width="650px"
        persistent
      >
        <v-card>
          
          <span>
              <v-card-title>
                <span>
                  <h6 class="orange--text">Paciente menor de idade!</h6>
                  <h5>Recebemos de:</h5>
                </span>
              </v-card-title>


              <v-row class="mx-2" >
                <v-col cols="8">
                  <v-text-field
                    v-model="receber_de_nome"
                    label="Nome"
                    type="text"
                    dense
                    hide-details=""
                  >
                  </v-text-field>
                </v-col>
                
                <v-col cols="4">
                  <v-text-field
                    v-model="receber_de_cpf"
                    label="CPF"
                    type="text"
                    dense
                    hide-details=""
                    v-mask="'###.###.###-##'"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

          </span>

          <v-card-actions class="mt-3">

            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click="dialog_receber = false"
              class="mr-5"
            >
              Cancelar
            </v-btn>
            
            <v-btn
              color="primary"
              dark
              class=""
              @click="dialog_receber = false"
            >
              ok
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>

  
          <v-toolbar
            color="primary"
            elevation="0"
            class="no-print"
          >
  
  
              <v-spacer></v-spacer>
  
              <v-btn
                color="primary text--white"
                @click="imprimir()"
              >
                <v-icon color="white"> mdi-printer </v-icon>
                &nbsp imprimir
              </v-btn>
  
  
              <v-divider class="mx-2" vertical inset></v-divider>
  
  
              <v-btn
                  color="primary"
                  @click="close()"
              >
                  
                  <v-icon>mdi-close</v-icon>
                  &nbsp 
                  Fechar
                  
              </v-btn>
  
          </v-toolbar>
  
  
          <v-container fluid class="pa-2">


              <v-row dense>
  
                  <!-- COLUNA ESQUERDA -->
                  <v-card
                   class="no-print mt-1"
                   width="5%"
                   elevation="0"
                   height="100"
                  >
                      <v-card class="mt-3 ml-2 grey lighten-4" v-if="0>1">
                          <v-row>
                              <v-col cols="3 offset-md-1">
   
                              </v-col>
                          </v-row>
                      </v-card>
                  </v-card>   
  
  
                  <!-- COLUNA CENTRAL -->
                  <v-card
                   class="sem_borda"
                  >
                  <!-- COLUNA CENTRAL -->
                      <!-- INICIO -->
                      <v-card
                      height="6.6cm"
                      width="19cm"
                      class="grey lighten-4 ml-1 mb-3"
                      elevation="2"
                      v-for="(item, i ) in objVias"
                      :key="i"
                      >
                      <!-- {{item}}  -->
                      <v-container fluid class="pa-2">
                          <v-row dense>
  
                              <v-card
                              width="13.6cm"
                              height="3.5cm"
                              class=" pt-0"
                              elevation="2"
                              >
                                  <center>
                                      <img
                                      ref="print_logo"
                                      :src="urlLogo"
                                      PATH_LOGO
                                      style="max-width: auto; height: 2.5cm"
                                      />
                                      <!-- src="http://localhost\vue_cfc\public\shalon.png" -->
                                  </center>
                                  <div 
                                  class="ml-1"
                                  style="margin-top: -0.3cm"
                                  >
  
                                       <p class="inline h7"> {{ dados.EMPRESA_TIPO }} : {{ dados.EMPRESA_CNPJ }}</p>
                                      &nbsp
                                      
                                      <p class="inline h7">FONES: {{ dados.EMPRESA_FONES }} </p>
                                      &nbsp
                                      <p class="inline h7"> {{ dados.EMPRESA_EMAIL }} </p>
                                  </div>
                                  
                                  <div 
                                  class="ml-1"
                                  style="margin-top: -0.2cm"
                                  >
                                      <p class="inline h7" > {{ dados.EMPRESA_END }}</p>
                                  </div>
                              </v-card>
  
                              <v-card
                              width="5cm"
                              height="3.5cm"
                              class=" px-2 ml-1"
                              elevation="2"
                              >
  
                              <h3 class="inline">RECIBO</h3> 
                              
                              <h6 class="inline">&nbsp {{ dados.CODIGO }}</h6> 
  
                              <div style="display: flex; justify-content: flex-end; margin-top:-25px; margin-bottom:2px">
                                  <h6 class="inline">{{ i + 1}}ª Via</h6> 
                              </div>
  
                              <!-- <h6 class="none">SEQ: 29245</h6> -->
  
                              <h2> R$ {{ (dados.VALOR) }} </h2>
                              <h5 class="inline none">Recebido em </h5>
                              <h4 class="inline"> {{ (dados.x_DATA)? dados.x_DATA: hoje }} </h4>
                              <!-- <center> -->
                                <h5 class="inline none">  {{ dados.FORMA_PAGAMENTO }} </h5>
                              <!-- </center> -->
                              
                              <!-- <h6 class="inline none">Venc. </h6>
                              <h5 class="inline"> 20/04/2022 </h5> -->
                              
                              </v-card>
  
                              <v-card
                              height="1cm"
                              width="18.7cm"
                              class=" mt-1 pa-1"
                              elevation="2"
                              >
                                <!-- <p class="inline cap">Recebemos de  </p> -->
                                <h6 class="inline none">Recebemos de: </h6>
                                
                                <!-- <h4 class="inline"> {{ (dados.NOME) ? dados.NOME : dados.NOME_AGENDA }} </h4> -->
                                <h4 class="inline"> {{ receber_de_nome }} </h4>
                                
                                <span v-if="(dados.CPF)">
                                  <p class="inline cap"> &nbsp CPF:  </p>
                                  <!-- <h5 class="inline"> {{ dados.CPF  }} </h5> -->
                                  <h5 class="inline"> {{ receber_de_cpf  }} </h5>
                                </span>
                                
                                <span v-if="(dados.PROCESSO)">
                                  <p class="inline cap"> &nbsp PROCESSO:  </p>
                                  <h5 class="inline"> {{ dados.PROCESSO  }} </h5>
                                </span>

                              </v-card>
  
                              <!-- <v-card
                              height="1cm"
                              width="3cm"
                              class=" mt-1 pa-1"
                              >
                              <h6 class="none">PRO: {{ dados.PROCESSO }} </h6>
                              <h6 class="none">IDE: {{ dados.CODIGO_ALUNO }}</h6>
                              </v-card> -->
  
                              <v-card
                              class=" mt-1 pa-1"
                              height="1.7cm"
                              width="12.1cm"
                              elevation="2"
                              >
                              <h6 class=" none">Referente à: </h6>
                              <h6 class="inline none">
                               ({{ dados.x_DATA }}) {{ dados.PROCEDIMENTO }} com {{ dados.PROFISSIONAL }}  {{ (dados.CRM_DOC>0) ? `- ${(dados.CNES_PF)  || ''}  ${dados.CRM_DOC}`: '' }} 
                              </h6>
                              </v-card>
  
                              <v-card
                              height="1.7cm"
                              width="6.6cm"
                              class=" mt-1 pa-1"
                              elevation="2"
                              >
                              <center>
                                  <h6 class="none pt-5">____________________________________________ </h6>
                                  <h6 class="none"> {{ dados.QUEM_EFETUOU }} </h6>
  
                                  <h6 class="none pt-1"> {{ dados.EMPRESA_CIDADE }}, {{ hoje }}</h6>
                              </center>
                              </v-card>
  
                          </v-row>
                      </v-container>
                      </v-card>
                  </v-card>   
  
                  <v-spacer></v-spacer>
                  <!-- COLUNA DIREITA -->
                  <v-card
                   class="no-print mt-1"
                   width="300"
                   elevation="0"
                   height="100"
                  >
  
                      <v-card class="mt-3 ml-2 grey lighten-4">
                          <v-row>
                              <v-col cols="4 offset-md-1">
                                  <!-- <v-card> -->
                                      <!-- <v-text-field
                                      v-model="nVias"
                                      label="Nº de Vias"
                                      type="number"
                                      @input="mudaVias(nVias)"
                                      ></v-text-field> -->
  
                                      <v-select
                                      v-model="nVias"
                                      :items="['1','2']"
                                      label="Nº de Vias"
                                      @input="mudaVias(nVias)"
                                      ></v-select>
                                  <!-- </v-card> -->
                              </v-col>
                          </v-row>
                      </v-card>
                  </v-card> 
  
              </v-row>
          </v-container>
        </v-card>
      </v-dialog>
  </v-row>
  </template>
  
  <script>
  
  import DSibasico from '../assets/js/DSi_basico'//chamo funcao do arquivo
  import { logado } from '../assets/js/DSi_rotinas'
  
  export default {
  
      data: () => ({

          dialog_receber: false,
          receber_de_nome: '',
          receber_de_cpf: '',
  
          obj_dados_empresa: null,
          obj_dados_paciente: null,
          obj_doc_selecionado: null,
          obj_dados_profissional: null,

          dialog_print: true,
          dados:[],
          
          nVias: '2',
          objVias:[],
          urlLogo:'',
  
      }),
  
      created(){
         this.onCreated() 
      },
  
      methods:{


          calcula_idade(data,campos){
            let s = DSibasico.calcula_idade(data, campos)

            if (s.years <18){
              this.is_menor = true
            }else{
              this.is_menor = false
            }

            return s.texto
          },

          getFormatDate(data){
            let dia =   data.substring(8,10) + '/'+ data.substring(5,7)+ '/'+ data.substring(0,4);//formato a dd/mm/yyyy
            return dia 
          },

          async go_receber_de(){

            let dados =  Object.assign({}, this.obj_dados_paciente)//clono


            let ssidade
            if (dados.DATANASCIMENTO){
              ssidade = this.calcula_idade(this.getFormatDate(dados.DATANASCIMENTO), 1)
            }
            // alert(ssidade)
            
            let idade = 0
            try {
              idade = ssidade.match(/\d+/); // Captura apenas os dígitos da string
            }catch(e){}
            // alert(idade)


            // PEGA OS DADOS DO PACIENTE
            this.receber_de_nome = (dados.NOME) ? dados.NOME : dados.NOME_AGENDA 
            this.receber_de_cpf  =  dados.CPF


            // é menor entao pega o nome da mae para mostrar no recibo
            if (idade<18){

              if (confirm("Paciente menor de idade! Usar os dados da mãe?")){
                
                this.receber_de_nome = dados.NOMEMAE
                this.receber_de_cpf  = ''

                this.dialog_receber = false
                this.dialog_receber = true
                
              }
            }


          },
  
          imprimir(){
              window.print()
          },
  
          close(){
              // this.$router.push('/agenda')//redireciono para a pagina do router
              window.close()
          },
  
          async onCreated(){
              await this.logado()//chamo funcoes basicas mas somente se logado
              await this.carrega_dados()
              await this.go_receber_de()
          },
  
          async logado(){
  
            let r = await logado(this)
  
          },
  
          async carrega_dados(){
              
              this.dados   =  (sessionStorage['recibo:dados']) ? JSON.parse(sessionStorage['recibo:dados'])   : '';

              try{
                this.obj_dados_profissional = JSON.parse(sessionStorage['obj_dados_profissional'])
              }catch(e){}


              this.urlLogo =  sessionStorage['emp_url_logo']

              // caso seja cpf pega a logo do profissional
              if (this.dados.EMPRESA_TIPO == 'CPF'){
                try{
                  // this.urlLogo = '/api/' + 'images/'+ this.obj_dados_profissional.PATH_LOGO || sessionStorage['emp_url_logo']
                  this.urlLogo = 'images/'+ this.obj_dados_profissional.PATH_LOGO || sessionStorage['emp_url_logo']
                }catch(e){}
              }
              

              this.obj_dados_paciente  = JSON.parse(sessionStorage['obj_dados_paciente'])
              this.obj_dados_empresa   = JSON.parse(sessionStorage['obj_dados_empresa'])
              this.obj_doc_selecionado = JSON.parse(sessionStorage['obj_doc_selecionado'])
  
              this.define_data_hora()
  
              this.mudaVias('2')
          },
  
          mudaVias(vias){
                
              this.objVias = []//limpa
  
              let obj = {}
              for (let i = 1; i <=vias; i++){
                  this.$set(obj, 'n',i)
                  this.objVias.push(obj)
              }
          },
  
          define_data_hora(){
           DSibasico.define_data_hora(this)//passo o this para que la ele defina as variaveis daqui
          },
  
          getFormatDate(data){
  
              if (data){
                  let dia =   data.substring(8,10) + '/'+ data.substring(5,7)+ '/'+ data.substring(0,4);//formato a dd/mm/yyyy
                  return dia 
              }
              else{
                  return '' 
              }
  
          },
  
      },
  
  }
  </script>
  
  
  <style scoped>
  
  
  @page:first {
     margin: 0.9cm !important;           
  } 
  
  
    .container{
      max-width: 100%;
    }
  
  
  .inline{
    display: inline  !important;
  }
  
  .cap{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
  }
  
  .none{
    font-weight: normal;
    /* font-weight (bold, bolder, lighter, normal e 100-900 */
  }
  
  .h7{
    font-size: 9px !important;
  }
  
  .sem_borda{
       /* sem borda */
      border: none !important;
      box-shadow: none !important;
  }
  
  
  
  @media print {
    .no-print {
      display: none !important;
    }

    @page{
      margin-left: 5mm !important; /* Margem esquerda de 20mm */
      margin-right: 5mm !important; /* Margem direita de 10mm */
    }
  }
  
  
  /* @media print {
    body * {
      visibility: hidden;
      display: none; 
    }
    .no-print {
      display: none !important;
    }
    .print {
      visibility: visible;
      display: block; 
    }
  } */
  
  
  .count_item {
    position: relative;
    top: 0px;
    font-size: 90%;
    vertical-align: super;
    color: red;
  }
  
  
  </style>
  
  
  