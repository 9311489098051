<template>
 <div
  class="mt-0"

 >



 <!-- obj_definicoes:{{ obj_definicoes }} <br/><br/> -->
 <!-- modelo_impressao:{{ modelo_impressao }} <br/><br/> -->
 <!-- obj_dados_paciente:{{ obj_dados_paciente }} <br/><br/> -->
 <!-- obj_dados_profissional:{{ obj_dados_profissional }} <br/><br/> -->
 <!-- obj_exames:{{ obj_exames }} <br/><br/> -->
 



 <!-- Dialog para editar o nome -->
 <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <!-- <v-card-title>
            Editar Nome
          </v-card-title> -->

          <v-card-text>

            <v-row>
            <v-col cols="10">
              <v-text-field 
              v-model="edit_objeto[edit_campo]" 
              hide-details=""
              autofocus 
              @keyup="allUpper($event, 'edit_objeto' ,edit_campo)"
              />
            </v-col>
            <v-col cols="2">
              <v-card-actions class="pt-5">
            <v-btn @click="dialog=false" color="primary">OK</v-btn>
          </v-card-actions>
            </v-col>
          </v-row>
          

          </v-card-text>

        </v-card>
      </v-dialog>


 <v-dialog
    v-model="dialog_opcoes"
    max-width="699px"
    scrollable
>
  <v-card
    class="mx-auto"
  >
    <v-card-title class=" grey lighten-5">
      <h5>Definições</h5>

      <v-spacer></v-spacer>
      
      <v-icon
        color="red"
        @click="dialog_opcoes=!dialog_opcoes"
      >
        mdi-close
      </v-icon>

    </v-card-title>

    <v-card-text>
      <v-container class="mx-0 px-0 mt-2">
        <v-row v-if="(modelo_impressao=='GUIA_INTERNACAO')" >
          
          <v-col
            v-for="(item, i) in obj_definicoes" :key="i"
            :cols="item.cols" 
            class=""
          >      
            <v-text-field v-if="item.tag=='v-text'"
              dense
              v-model="edit_objeto[item.text]"
              :label="item.caption"
              hide-details
              type="text"
            ></v-text-field>

            <v-select 
                v-if="item.tag=='v-select'"
                :class="[item.class]"
                dense
                v-model="edit_objeto[item.text]"
                :label="item.caption"
                :items="item.tag_items"
                item-value='CODIGO'
                item-text='NOME'
                hide-details=""
                :hint="item.hint"
            >

            <template v-slot:label>
                <v-tooltip bottom v-if="item.hint">
                    <template v-slot:activator="{ on, attrs }">
                      <span
                          v-bind="attrs"
                          v-on="on"
                      > 
                        <span :class="item.class">
                          {{ item.caption }} 
                        </span>
                      </span>

                    </template>
                    <span> {{ item.hint }} </span>
                  </v-tooltip>
              </template>

            </v-select>



            <v-textarea
              v-model="edit_objeto[item.text]"
              :label="item.caption"
              v-if="item.tag == 'v-textarea'"
              :hint="item.hint"
              :persistent-hint="item.cols>2"
              dense
              :rows="item.rows"
              :filled="item.solo=='S'"
            >
            <!-- vai_imprimir -->
            </v-textarea>
            
          </v-col> 

          

          <v-card-actions>
            <v-btn color="primary" @click="dialog_opcoes=!dialog_opcoes">
             ok
            </v-btn>
          </v-card-actions>        

          
          <!-- <v-spacer></v-spacer> -->


        </v-row>
        <v-row v-if="(modelo_impressao=='SADT')">
          
          <v-col
            cols="12"
            md="10"
            class="ml-2"
          >      
            <v-text-field
              dense
              v-model="def.indicacao_clinica"
              label="24-Indicação Clínica"
              hide-details
              type="text"
            ></v-text-field>
            <!-- @input="def.motivo_processo = def.motivo_processo.toUpperCase()" -->
          </v-col> 
          
          <v-col
            cols="6"
            md="2"
            class="ml-2"
          >      
            <v-text-field
              dense
              v-model="def.qt_solic"
              label="28-Qt.Solic."
              hide-details
              type="number"
            ></v-text-field>
            <!-- @input="def.motivo_processo = def.motivo_processo.toUpperCase()" -->
          </v-col> 
          

          <v-col
            cols="12"
            md="1"
            class="ml-2"
          >      
          <v-btn color="primary"
          @click="dialog_opcoes=!dialog_opcoes"
          >
            ok
          </v-btn>
          </v-col> 
          

          
          <v-spacer></v-spacer>


        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</v-dialog>




 
    <v-row justify="center">
        <!-- <v-dialog
        v-model="dialog_print"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        > -->

        <v-card
         class="sem_borda pt-1 pr-1" 
         width="100%"
        >
            <v-toolbar
            dark
            color="primary"
            elevation="0"
            class="no-print"
            style="position: sticky; top: 0; z-index: 99;"
            >
            <!-- color="grey lighten-0" -->
            
            <v-toolbar-title>
              <span v-if="(modelo_impressao=='SADT')">
                Tem que imprimir no modo Paisagem
              </span>
            </v-toolbar-title>


            <v-spacer></v-spacer>

            <!-- <div>
              <v-btn color="primary" @click="setPrintStyle('landscape')">Imprimir em Paisagem</v-btn>
              <v-btn color="secondary" @click="setPrintStyle('portrait')">Imprimir em Retrato</v-btn>
            </div> -->

            <!-- F5 ATUALIZA A PAGINA -->
            <v-btn
              @click="recarregar_pagina()"
              color="indigo"
            >
              <v-icon color="white"> mdi-refresh </v-icon>
              <!-- &nbsp RECARREGAR -->
            </v-btn>

            <v-divider class="mx-2" vertical inset></v-divider>


            <v-btn
              @click="imprimir"
              color="primary text--white"
            >
              <v-icon color="white"> mdi-printer </v-icon>
              &nbsp imprimir
            </v-btn>

            <v-divider class="mx-2" vertical inset></v-divider>


            <v-btn
                
                color="primary"
                @click="close"
            >
                
                <v-icon>mdi-close</v-icon>
                &nbsp 
                Fechar
                
            </v-btn>

            <!-- <v-spacer></v-spacer>
            <v-toolbar-items>

              <v-btn
                @click="imprimir"
                color="primary text--white"
              >
                <v-icon color="white"> mdi-printer </v-icon>
              </v-btn>
            </v-toolbar-items> -->

            </v-toolbar>


            <!-- INICIO -->
             <span v-if="(modelo_impressao=='SADT')"> 
              <v-card
              height="18.7cm"
              width="27.5cm"
              elevation="0"
              class="ml-4 mt-2"
              v-for="(itemalfa, j) in lista_exames.length"
              :key="j"
              >
              <!-- {{ itemalfa }} -->
              <!-- {{ j }} -->
              <!-- {{ lista_exames[j] }} -->
              <!-- {{ lista_exames.length }} tamanho -->
              <!-- class="grey lighten-4 ml-1" -->
              <v-container fluid class="pa-2">
                  <v-row dense>

                      <!-- 1 LINHA     -->
                      <v-card
                      width="5.5cm"
                      height="1.3cm"
                      class=""
                      outlined
                      >
                          <center>
                            <p
                            v-if="path_logo==false"
                            class="mt-3"
                            >{{ dados.CONVENIO }}
                            </p>

                              <img
                              ref="print_logo"
                              :src="path_logo"
                              style="max-width: auto; height: 1.2cm"
                              v-if="path_logo"
                              />
                              <!-- src="http://localhost\vue_cfc\public\unimed.png" -->
                          </center>
                      </v-card>

                      <v-card
                      width="21.7cm"
                      height="1.3cm"
                      class=""
                      outlined
                      >
                        <!-- <center> -->
                          <h5 class="mt-3 ml-10"> GUIA DE SERVIÇO PROFISSIONAL / SERVIÇO AUXILIAR DE DIAGNÓSTICO E TERAPIA - SP/SADT </h5>
                          <!-- <h5>DIAGNÓSTICO E TERAPIA - SP/SADT </h5> -->
                        <!-- </center> -->

                          <div style="display: flex; justify-content: flex-end; margin-top:-23px; margin-bottom:2px">
                              <h6 class="inline">2 - Nº Guia do Prestador &nbsp &nbsp &nbsp&nbsp </h6>
                          </div>
                      </v-card>


                      <!-- 2 LINHA     -->
                      <v-card
                      width="3.3cm"
                      height="0.6cm"
                      outlined
                      >
                        <p class="top">1-Registro ANS</p>
                      </v-card>

                      <v-card
                      width="7.5cm"
                      height="0.6cm"
                      outlined
                      >
                        <p class="top">3-Nº Guia Principal</p>
                      </v-card>

                      <v-card
                      width="3cm"
                      height="0.6cm"
                      outlined
                      >
                        <p class="top">4-Data da Autorização</p>
                      </v-card>

                      <v-card
                      width="2.4cm"
                      height="0.6cm"
                      outlined
                      >
                        <p class="top">5-Senha</p>
                      </v-card>

                      <v-card
                      width="3cm"
                      height="0.6cm"
                      outlined
                      >
                        <p class="top">6-Data Validade da Senha</p>
                      </v-card>

                      <v-card
                      width="3cm"
                      height="0.6cm"
                      outlined
                      >
                        <p class="top">7-Data de Emissão da Guia</p>
                      </v-card>

                      <!-- 3 LINHA -->
                      <v-card
                      width="27.2cm"
                      height="0.2cm"
                      class="titulo"
                      outlined
                      >
                        <p class="top-"> Dados do Beneficiário</p>
                      </v-card>

                      <v-card
                      width="7.5cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 8-Número da Carteira</p>
                        <p class="texto">  {{ obj_dados_paciente.NUMCARTEIRA }} </p>

                        
                      </v-card>

                      <v-card
                      width="2cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 9-Plano</p>
                      </v-card>

                      <v-card
                      width="3cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 10-Validade da Carteira</p>
                      </v-card>

                      <v-card
                      width="9.2cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 11-Nome</p>
                        <p class="texto">  {{ dados.PACIENTE }} </p>
                      </v-card>

                      <v-card
                      width="5.5cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 12-Número do Cartão Nacional de Saúde</p>
                      </v-card>

                      <!-- 4 LINHA -->
                      <v-card
                      width="27.2cm"
                      height="0.2cm"
                      class="titulo"
                      outlined
                      >
                        <p class="top-"> Dados do Contratado Solicitante </p>
                      </v-card>

                      <v-card
                      width="5.5cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top">13-Código na Operadora / CNPJ / CPF </p>
                      </v-card>

                      <v-card
                      width="10.5cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 14-Nome do Contratante </p>
                        <p class="texto">  {{ dados.CLINICA }} </p>
                      </v-card>

                      <v-card
                      width="2cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 15-Código CNES </p>
                      </v-card>

                      <v-card
                      width="10.5cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 16-Nome do Profissional Solicitante </p>
                        <p class="texto">  {{ dados.PROFISSIONAL }} </p>
                      </v-card>

                      <v-card
                      width="4.4cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 17-Conselho Profissional </p>
                        <p class="texto">  {{ dados.CONSELHO }} </p>
                      </v-card>

                      <v-card
                      width="4cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 18-Número no Conselho </p>
                        <p class="texto">  {{ dados.CRM }} </p>
                      </v-card>

                      <v-card
                      width="1.5cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 19-UF</p>
                        <p class="texto">  {{ dados.CRM_UF }} </p>
                      </v-card>

                      <v-card
                      width="2cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 20-Código CBO S </p>
                      </v-card>


                      <!-- 5 LINHA -->
                      <v-card
                      width="27.2cm"
                      height="0.2cm"
                      class="titulo"
                      outlined
                      >
                        <p class="top-"> Dados da Solicitação / Procedimentos e Exames Solicitados</p>
                      </v-card>

                      <v-card
                      width="5cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 21-Data/Hora da Solicitação </p>
                      </v-card>

                      <v-card
                      width="4cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 22-Caráter da Solicitação</p>
                        <p class="meio"> ______ &nbsp <strong> E</strong>-Eletiva <strong>U</strong>-Urgência/Emergência</p>
                      </v-card>

                      <v-card
                      width="2.3cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 23-CID 10</p>
                      </v-card>

                      <v-card
                      width="15.9cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 24-Indicação Clínica (obrigatório se pequena cirurgia, terapia, consulta de referência e alto custo) </p>
                        <p class="texto">  {{ def.indicacao_clinica }} </p>
                      </v-card>

  <!-- {{ lista_exames }} -->

                      <!-- LINHA -->
                      <v-card
                      width="27.2cm"
                      height="2.5cm"
                      outlined
                      class=""
                      >

                          <v-row dense class="ma-0">
                          <v-card
                          width="1.3cm"
                          height="0.9cm"
                          outlined
                          class="sem_borda"
                          >
                              <p class="top"> 25-Tabela </p>
                              <p 
                                v-for="(item, i, index) in lista_exames[j]"
                                :key = "i"
                                class="texto borda">{{ index +1 }} |
                              </p>
                          </v-card>

                          <v-card
                          width="3cm"
                          height="0.9cm"
                          outlined
                          class="sem_borda"
                          >
                              <p class="top"> 26-Código do Procedimento</p>
                              <p 
                                v-for="(item, i) in lista_exames[j]"
                                :key = "i"
                                class="texto borda"> &nbsp {{ item.COD96 }} </p>
                          </v-card>

                          <v-card
                          width="19.6cm"
                          height="0.9cm"
                          outlined
                          class="sem_borda"
                          >
                              <p class="top"> 27-Descrição </p>
                              <p 
                                v-for="(item, i) in lista_exames[j]"
                                :key = "i"
                                class="texto borda">&nbsp {{ item.EXAME }} </p>
                          </v-card>

                          <v-card
                          width="1.3cm"
                          height="0.9cm"
                          outlined
                          class="sem_borda"
                          >
                              <p class="top"> 28-Qt.Solic.</p>
                              <p 
                                v-for="(item, i) in lista_exames[j]"
                                :key = "i"
                                class="texto borda pl-1">
                                  <!-- &nbsp 1 -->
                                  {{ def.qt_solic }}
                              </p>
                          </v-card>

                          <v-card
                          width="1.3cm"
                          height="0.9cm"
                          outlined
                          class="sem_borda"
                          >
                              <p class="top"> 29-Qt.Autor.</p>
                              <p 
                              v-for="(item, i) in lista_exames[j]"
                                :key = "i"
                              class="texto borda"> &nbsp</p>
                          </v-card>





                          </v-row>
                      </v-card>



                      <!-- 6 LINHA -->
                      <v-card
                      width="27.2cm"
                      height="0.2cm"
                      class="titulo"
                      outlined
                      >
                        <p class="top-"> Dados do Contratado Executante </p>
                      </v-card>

                      <v-card
                      width="5.4cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 30-Código na Operadora / CNJPJ / CPF </p>
                      </v-card>

                      <v-card
                      width="8cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 31-Nome do Contratado </p>
                      </v-card>

                      <v-card
                      width="1cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top">32-T.L.  </p>
                      </v-card>

                      <v-card
                      width="4.7cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 33-34-35-Logradouro - Número - Complemento  </p>
                      </v-card>

                      <v-card
                      width="2.5cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 36-Município </p>
                      </v-card>

                      <v-card
                      width="0.8cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 37-UF </p>
                      </v-card>

                      <v-card
                      width="1.4cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 38-Cód.IBGE </p>
                      </v-card>

                      <v-card
                      width="1.5cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 39-CEP </p>
                      </v-card>

                      <v-card
                      width="1.9cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 40-Código CNES </p>
                      </v-card>

                      <v-card
                      width="5.4cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 40a-Código na Operadora / CPF do exec.complementar </p>
                      </v-card>

                      <v-card
                      width="8.7cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 41-Nome do Profissional Executante / Complementar </p>
                      </v-card>

                      <v-card
                      width="3.9cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 42-Conselho Profissional </p>
                      </v-card>

                      <v-card
                      width="3.5cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 43-Número no Conselho </p>
                      </v-card>

                      <v-card
                      width="1cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 44-UF </p>
                      </v-card>

                      <v-card
                      width="2cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 45-Código CBO S </p>
                      </v-card>

                      <v-card
                      width="2.7cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 45a-Graude Participação </p>
                      </v-card>


                      <!-- 7 LINHA -->
                      <v-card
                      width="27.2cm"
                      height="0.2cm"
                      class="titulo"
                      outlined
                      >
                        <p class="top-"> Dados do Atendimento </p>
                      </v-card>

                      <v-card
                      width="11.5cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 46-Tipo Atendimento </p>
                        <p class="meio">________  </p>
                        
                        <div style="display: flex; justify-content: flex-end; margin-top:-20px; margin-bottom:2px">
                          <p class="meio"> <b>01</b>-Remoção &nbsp <b>02</b>-Pequena Cirurgia &nbsp <b>03</b>-Terapias &nbsp <b>04</b>-Consulta  &nbsp <b>05</b>-Exame  &nbsp <b>06</b>-Atendimento Domiciliar &nbsp &nbsp &nbsp</p> 
                        </div>
                        
                        <div style="display: flex; justify-content: flex-end; margin-top:-7px; margin-bottom:2px">
                          <p class="meio"> <b>07</b>-SADT Internado &nbsp <b>08</b>-Quimioterapia  &nbsp <b>09</b>-Radioterapia  &nbsp <b>10</b>-TRS-Terapia Renal Substitutiva  &nbsp &nbsp &nbsp</p> 
                        </div>
                      </v-card>

                      <v-card
                      width="7.5cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 47-Indicação de Acidente </p>
                        <p class="meio"> _____ &nbsp <b>0</b>-Acidente ou doença relacionado ao trabalho &nbsp <b>1</b>-Trânsito &nbsp  <b>2</b>-Outros</p>
                      </v-card>
                      
                      <v-card
                      width="8.2cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 48-Tipo de Saída </p>
                        <p class="meio"> _____ &nbsp <b>1</b>-Retorno &nbsp <b>2</b>-Retorno SADT &nbsp  <b>3</b>-Referência &nbsp <b>4</b>-Internação &nbsp <b>5</b>-Alta &nbsp <b>6</b>-Óbito &nbsp </p>
                      </v-card>



                      <!-- 8 LINHA -->
                      <v-card
                      width="27.2cm"
                      height="0.2cm"
                      class="titulo"
                      outlined
                      >
                        <p class="top-"> Consulta Referência </p>
                      </v-card>

                      <v-card
                      width="3cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 49-Tipo de Doença </p>
                        <p class="meio"> _____ &nbsp <b>A</b>-Aguda &nbsp <b>C</b>-Crônica </p>
                      </v-card>

                      <v-card
                      width="5cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 50-Tempo de Doença </p>
                        <p class="meio"> ______ | ______ &nbsp <b>A</b>-Anos &nbsp <b>M</b>-Meses &nbsp <b>D</b>-Dias</p>
                      </v-card>


                      <!-- 9 LINHA -->
                      <v-card
                      width="27.2cm"
                      height="0.2cm"
                      class="titulo"
                      outlined
                      >
                        <p class="top-"> Procedimentos e Exames Realizados </p>
                      </v-card>

                      <v-card
                      width="27.2cm"
                      height="2.5cm"
                      outlined
                      class=""
                      >

                          <v-row dense class="ma-0">
                          <v-card
                          width="2.5cm"
                          height="0.9cm"
                          outlined
                          class="sem_borda"
                          >
                              <p class="top"> 51-Data </p>
                              <p 
                                v-for="(item, i) in count51"
                                :key = "i"
                                class="meio"> <b>{{i+1}}-</b> ______/______/______ </p>
                          </v-card>

                          <v-card
                          width="1.9cm"
                          height="0.7cm"
                          outlined
                          class="sem_borda"
                          >
                              <p class="top"> 52-Hora Inicial </p>
                              <p 
                                v-for="(item, i) in count51"
                                :key = "i"
                              class="meio"> ______:______ <b>&nbsp &nbsp a </b></p>
                          </v-card>


                          <v-card
                          width="1.7cm"
                          height="0.9cm"
                          outlined
                          class="sem_borda"
                          >
                              <p class="top"> 53-Hora Final </p>
                              <p 
                              v-for="(item, i) in count51"
                                :key = "i"
                              class="meio"> ______:______ </p>
                          </v-card>

                          <v-card
                          width="1.5cm"
                          height="0.9cm"
                          outlined
                          class="sem_borda"
                          >
                              <p class="top"> 54-Tabela </p>
                              <p 
                              v-for="(item, i) in count51"
                                :key = "i"
                              class="meio"> __________</p>
                          </v-card>

                          <v-card
                          width="3cm"
                          height="0.9cm"
                          outlined
                          class="sem_borda"
                          >
                              <p class="top"> 55-Código do Procedimento </p>
                              <p 
                              v-for="(item, i) in count51"
                                :key = "i"
                              class="meio"> ____________________________</p>
                          </v-card>

                          <v-card
                          width="5.5cm"
                          height="0.9cm"
                          outlined
                          class="sem_borda"
                          >
                              <p class="top"> 56-Descrição </p>
                              <p 
                              v-for="(item, i) in count51"
                                :key = "i"
                              class="meio"> _____________________________________________</p>
                          </v-card>

                          <v-card
                          width="1cm"
                          height="0.9cm"
                          outlined
                          class="sem_borda"
                          >
                              <p class="top"> 57-Qtde </p>
                              <p 
                              v-for="(item, i) in count51"
                                :key = "i"
                              class="meio"> ________ </p>
                          </v-card>

                          <v-card
                          width="1cm"
                          height="0.9cm"
                          outlined
                          class="sem_borda"
                          >
                              <p class="top"> 58-Via </p>
                              <p 
                                v-for="(item, i) in count51"
                                :key = "i"
                              class="meio"> ________ </p>
                          </v-card>

                          <v-card
                          width="1cm"
                          height="0.9cm"
                          outlined
                          class="sem_borda"
                          >
                              <p class="top"> 59-Tec </p>
                              <p 
                              v-for="(item, i) in count51"
                                :key = "i"
                              class="meio"> ________ </p>
                          </v-card>

                          <v-card
                          width="1.5cm"
                          height="0.9cm"
                          outlined
                          class="sem_borda"
                          >
                              <p class="top"> 60% Red./Acr. </p>
                              <p 
                              v-for="(item, i) in count51"
                                :key = "i"
                              class="meio"> _____________ </p>
                          </v-card>

                          <v-card
                          width="3.2cm"
                          height="0.9cm"
                          outlined
                          class="sem_borda"
                          >
                              <p class="top"> 61-Valor Unitário R$ </p>
                              <p 
                              v-for="(item, i) in count51"
                                :key = "i"
                              class="meio"> _____________________________ </p>
                          </v-card>

                          <v-card
                          width="3.3cm"
                          height="0.9cm"
                          outlined
                          class="sem_borda"
                          >
                              <p class="top"> 62-Valor Total R$ </p>
                              <p 
                              v-for="(item, i) in count51"
                                :key = "i"
                              class="meio"> ______________________________ </p>
                          </v-card>
                          </v-row>
                      </v-card>


                      <!-- LINHA     -->
                      <v-card
                      width="27.2cm"
                      height="1.1cm"
                      outlined
                      class=""
                      >

                          <v-row dense class="ma-0">
                          <v-card
                          width="27cm"
                          height="0.9cm"
                          outlined
                          class="sem_borda"
                          >
                              <p class="top"> 63-Data e Assinatura de Procedimentos em Séries </p>
                              <p 
                                v-for="(item, i) in 2"
                                :key = "i"
                                class="meio"> 
                                <b>{{i+1}}-</b> ______/______/______  &nbsp&nbsp ________________________  &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp 
                                <b>{{i+3}}-</b> ______/______/______  &nbsp&nbsp ________________________  &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp
                                <b>{{i+5}}-</b> ______/______/______  &nbsp&nbsp ________________________  &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp
                                <b>{{i+7}}-</b> ______/______/______  &nbsp&nbsp ________________________  &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp
                                <b>{{i+9}}-</b> ______/______/______  &nbsp&nbsp ________________________  &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp
                              </p>
                          </v-card>
                          </v-row>
                      </v-card>



                      <!-- LINHA     -->
                      <v-card
                      width="27.2cm"
                      height="1cm"
                      outlined
                      class=""
                      >
                      <p class="top"> 64-Observação</p>
                      </v-card>



                      <!-- LINHA -->
                      <v-card
                      width="3.8cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 65-Total Procedimentos R$ </p>
                      </v-card>

                      <v-card
                      width="3.8cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 66-Total Taxas e Aluguéis R$ </p>
                      </v-card>

                      <v-card
                      width="3.8cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 67-Total Materiais R$ </p>
                      </v-card>

                      <v-card
                      width="3.8cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 68-Total Medicamentos R$ </p>
                      </v-card>

                      <v-card
                      width="4cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 69-Total Diárias R$ </p>
                      </v-card>

                      <v-card
                      width="4cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 70-Total Gases Medicinais R$ </p>
                      </v-card>

                      <v-card
                      width="4cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 71-Total Geral da Guia R$ </p>
                      </v-card>



                      <!-- LINHA -->
                      <v-card
                      width="7cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 86-Data e Assinatura do Solicitante </p>
                      </v-card>
                      
                      <v-card
                      width="7cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 87-Data e Assinatura do Responsável pela Autorização </p>
                      </v-card>
                      
                      <v-card
                      width="6.2cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 88-Data e Assinatura do Beneficiário ou Responsável </p>
                      </v-card>
                      
                      <v-card
                      width="7cm"
                      height="0.8cm"
                      outlined
                      >
                        <p class="top"> 89-Data e Assinatura do Prestador Executante </p>
                      </v-card>

                  </v-row>
                  <v-card class="mx-4 blue no-print" height="1cm" inset></v-card>

              </v-container>
              
              </v-card>
            </span>

            

            <div elevation="" flat class="" v-if="(modelo_impressao=='GUIA_INTERNACAO')">


              <!-- Container da imagem -->
              <v-row dense class="position-relative orange" style="width: 25cm;">

                <!-- Imagem de fundo -->
                <v-col class="pt-2">
                  <img :src="imageUrl" class="background-image" />
                </v-col>

            
                <!-- <v-col class="" cols="2"> -->
                  
                
                <!-- Conteúdo sobreposto -->
                  <v-row dense :class="[`overlay-content ${(vai_imprimir)?'black':'blue'}--text`]"  style="margin-top: -40px">
                    <v-col class="" cols="">
                      
                            <p
                            v-if="!path_logo"
                            class="mt-3 ml-2 fs-20"
                            >{{ dados.CONVENIO }}
                            </p>

                              <img
                              ref="print_logo"
                              :src="path_logo"
                              style="max-width: auto; height: 1.2cm"
                              v-if="path_logo"
                              />
                        
                    </v-col>
                  </v-row>
                  
                  <v-row dense :class="[`overlay-content ${(vai_imprimir)?'black':'blue'}--text`]"  style="margin-top: 210px">
                    <v-col class="" cols="">
                      <span class="fs-18 bold hand "  @click="editar_campo('edit_objeto','NOME')"> {{ edit_objeto.NOME }}</span>
                    </v-col>
                  </v-row>
                  
                  <v-row dense :class="[`overlay-content ${(vai_imprimir)?'black':'blue'}--text`]"  style="margin-top: 334px">
                    <v-col class="" cols="auto">
                      <span class="fs-18 bold hand "  @click="editar_campo('edit_objeto','MEDICO')"> {{ edit_objeto.MEDICO }}</span>                    
                    </v-col>
                  </v-row>
                  
                  <v-row dense :class="[`overlay-content ${(vai_imprimir)?'black':'blue'}--text`]"  style="margin-top: 334px">
                    <v-col class="" cols="auto">
                      <span class="fs-18 bold hand"   @click="editar_campo('edit_objeto','CNES_PF')" style="margin-left:420px"> {{ edit_objeto.CNES_PF }}</span>
                    </v-col>
                  </v-row>
                  
                  <v-row dense :class="[`overlay-content ${(vai_imprimir)?'black':'blue'}--text`]"  style="margin-top: 334px">
                    <v-col class="" cols="auto">
                      <div class="fs-18 bold hand "  @click="editar_campo('edit_objeto','CRM')" style="margin-left:550px"> {{ edit_objeto.CRM }}</div>
                    </v-col>
                  </v-row>

                  <v-row dense :class="[`overlay-content ${(vai_imprimir)?'black':'blue'}--text`]"  style="margin-top: 334px">
                    <v-col class="" cols="auto">
                      <span class="fs-18 bold hand "  @click="editar_campo('edit_objeto','UF')" style="margin-left:815px"> {{ edit_objeto.UF || '' }}</span>
                    </v-col>
                  </v-row>
                  
                  
                  <v-row dense :class="[`overlay-content ${(vai_imprimir)?'black':'blue'}--text`]"  style="margin-top: 438px">
                    <v-col class="" style="margin-left:63px">
                      <span class="fs-20 bold hand "  @click="editar_campo('edit_objeto','C22')"> {{ edit_objeto.C22 }}</span>
                      <span class="fs-20 bold hand "  @click="editar_campo('edit_objeto','C23')" style="margin-left:135px"> {{ edit_objeto.C23 }}</span>
                      <span class="fs-20 bold hand "  @click="editar_campo('edit_objeto','C24')" style="margin-left:113px"> {{ edit_objeto.C24 }}</span>
                      <span class="fs-20 bold hand "  @click="editar_campo('edit_objeto','C25')" style="margin-left:122px"> {{ edit_objeto.C25 }}</span>
                      <span class="fs-20 bold hand "  @click="editar_campo('edit_objeto','C26')" style="margin-left:167px"> {{ edit_objeto.C26 }}</span>

                    </v-col>
                  </v-row>
                  
                  <v-row dense :class="[`overlay-content ${(vai_imprimir)?'black':'blue'}--text `]"  style="margin-top: 500px">
                    <v-col class="pa-0" style="margin-left:10px; width: 25cm; height: 4.2cm;" cols="auto">
                      <!-- 28 - indicacao clinica -->
                      <v-textarea
                        v-model="edit_objeto.C28" auto-grow dense solo flat class="fs-12">
                      </v-textarea>
                    </v-col>
                  </v-row>


                  <v-row dense :class="[`overlay-content ${(vai_imprimir)?'black':'blue'}--text `]"  
                    style="margin-top: 677px"
                  >
                    <v-col class="" cols="12" >
                      <span class="fs-20 bold hand "  @click="editar_campo('edit_objeto','C29')" style="margin-left:40px"> {{ edit_objeto.C29 }}</span>
                      <span class="fs-20 bold hand "  @click="editar_campo('edit_objeto','C30')" style="margin-left:115px"> {{ edit_objeto.C30 }}</span>
                      <span class="fs-20 bold hand "  @click="editar_campo('edit_objeto','C31')" style="margin-left:90px"> {{ edit_objeto.C31 }}</span>
                      <span class="fs-20 bold hand "  @click="editar_campo('edit_objeto','C32')" style="margin-left:110px"> {{ edit_objeto.C32 }}</span>
                    </v-col>
                  </v-row>


                  <v-row dense :class="[`overlay-content ${(vai_imprimir)?'black':'blue'}--text fs-16`]"  style="margin-top: 744px">


                    <v-col class="ml-4 mr-1" cols="auto" >
                      <span class="fs-12">34-Tabela</span>
                      <div v-for="(item, i) in obj_exames" :key="i" class="fs-15">
                           <!-- <span> {{ i+1 }} -  </span> -->
                          <span> |___|___| </span> 
                      </div>
                    </v-col>
                    
                    <!-- <v-col class="mx-1 " :cols="(vai_imprimir) ? 2 : 2" xl="1"> -->
                    <v-col class="mx-1 " cols="1">
                      <!-- <span class="fs-11">35-Código do Procedimento ou Item Assistencial</span> -->
                      <div class="fs-11 ">35-Cód.Proc.</div>
                      <div class="fs-11 mt-n2">ou Item Assis.</div>
                      <div v-for="(item, i) in obj_exames" :key="i" class="fs-15">
                          <span> {{ item.COD96 }} </span>
                      </div>
                    </v-col>

                    <!-- <v-col class="" :cols="(vai_imprimir) ? 6 : 3"  xl="3"> -->
                    <v-col class="" cols="8">
                      <span class="fs-12">36-Descrição</span>

                      <div v-for="(item, i) in obj_exames" :key="i" class="fs-15">
                          <span> {{ item.EXAME }} </span>
                      </div>
                    </v-col>

                    <v-col class="ml-n10 " :cols="(vai_imprimir) ? 2 : '2'" >
                      <v-row>
                        <v-col>
                          <span class="fs-10">37-QT Solic.</span>

                          <div v-for="(item, i) in obj_exames" :key="i" class="fs-15">
                               <span> {{ item.QTDESOLICITADA || 1}} </span>
                          </div>

                        </v-col>
                        <v-col>
                          <span class="fs-10">38-QT Aut.</span>
                          <div v-for="(item, i) in obj_exames" :key="i" class="fs-15">
                                <span> |___|___| </span> 
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  
                  </v-row>


                  <v-row dense :class="[`overlay-content ${(vai_imprimir)?'black':'blue'}--text`]"  style="margin-top: 1180px">
                    <v-col class="">
                      <div class="fs-13 bold hand "  @click="editar_campo('edit_objeto','C45')" style="margin-left:33px"> {{ edit_objeto.C45 }}</div>

                    </v-col>
                  </v-row>

                <!-- </v-col> -->

              </v-row>

            </div>

        </v-card>


        <!-- </v-dialog> -->
    </v-row>
 </div>

</template>

<script>
import { setPrintStyle } from '../assets/js/DSi_basico'
export default {

    props:{
        pro_dados: Object,
    },

    data: () => ({

      imageUrl: 'https://dsi.app.br/logos/GUIA_INTERNACAO.png',
      //imageUrl: 'http://localhost/GUIA_INTERNACAO.png',
      dialog: false,
      vai_imprimir: false,
      edit_campo:'',

      modelo_impressao: '',
      obj_dados_paciente: null,
      obj_dados_profissional: null,
      obj_exames:[],

      dialog_opcoes: false,
      def:{
        indicacao_clinica:'',
        qt_solic:'1'
      },

      obj_definicoes: [
        {text:'C22', value:'E'   ,cols:'3', tag:'v-select'  , caption:'22.Caráter da Solicitação', tag_items:['E','U'], hint:'(E)letiva (U)rgência / Emergência'},
        {text:'C23', value:'E'   ,cols:'3', tag:'v-select'  , caption:'23.Tipo de Internação',tag_items:['1','2','3','4','5'], hint:'(1)Clínica (2)Cirúrgica (3)Obstétrica (4)Pediátrica (5)Psiquiátrica'},
        {text:'C24', value:'E'   ,cols:'3', tag:'v-select'  , caption:'24.Regime de Internação', tag_items:['1','2','3'], hint:'(1)Hospitalar (2)Hospitalar-dia (3)Domiciliar'},
        {text:'C25', value:'1'   ,cols:'3', tag:'v-text'    , caption:'25.Quantidade de Diárias', hint:''},
        {text:'C26', value:'E'   ,cols:'3', tag:'v-select'  , caption:'26.Previsão de uso OPME', tag_items:['S','N'], hint:''},
        {text:'C28', value:'.'   ,cols:'9', tag:'v-textarea', caption:'28.Indicação Clínica', rows:'4', solo:'S', hint:'' },
        {text:'C29', value:' '   ,cols:'3', tag:'v-text'    , caption:'29.CID 10 Principal', hint:''},
        {text:'C30', value:' '   ,cols:'3', tag:'v-text'    , caption:'30.CID 10 (2)', hint:''},
        {text:'C31', value:' '   ,cols:'3', tag:'v-text'    , caption:'31.CID 10 (3)', hint:''},
        {text:'C32', value:' '   ,cols:'3', tag:'v-text'    , caption:'32.CID 10 (4)', hint:''},
        // {text:'C45', value:' '   ,cols:'12', tag:'v-text'    , caption:'45.Observação / Justificativa', hint:''},
        {text:'C45', value:''    ,cols:'12', tag:'v-textarea', caption:'45.Observação / Justificativa', rows:'4', solo:'S', hint:'' },


      ],

      edit_objeto:{ 
        'C22': 'E',
        'C23':'2',
        'C24':'1',
        'C25':'1',
        'C26':'S',
        'C28':'',
        'C29':' ',
        'C30':' ',
        'C31':' ',
        'C32':' ',
        'C45':'Justificativa ',

      },

      dialog_print: true,
      count51:5,
      lista_exames:[],
      // lista_exames:{},
      dados:{},
      path_logo:'',
    }),

    mounted(){

      // Receber a string
      let getString = sessionStorage.getItem('obj_sadt');

      // transformar em objeto novamente
      this.dados = JSON.parse(getString);

      // this.lista_exames = this.dados.EXAMES

      // if (this.modelo_impressao=='SADT'){
        this.dialog_opcoes = true
        this.monta_exames()
      // }

      // console.log('this.dados:');
      // console.log(this.dados);
      // console.log(this.dados.EXAMES);
      
      
      // this.dados = this.pro_dados

      this.define_logo(this.dados.CONVENIO)

    },

    created(){
      this.showDrawer()
      this.ler_session()

      setPrintStyle('portrait')
    },

    methods:{

      recarregar_pagina(){
        location.reload();
      },

      allUpper(event, obj_name,  field){
        // console.log('event:', event);
        
         let a = event.target.value;//captura o valor do event
         if (a!==''){  
             a = a.toUpperCase();
             this[obj_name][field] = a
         } 
      },

      editar_campo(item,campo){

        // this.$set(this[item], campo, 'E')
        this.edit_objeto = this[item]
        this.edit_campo  = campo

        // this[item].campo 
          this.dialog = true
      },

      ler_session(){

        this.obj_dados_paciente = JSON.parse(sessionStorage['obj_dados_paciente'])
        
        
        try{
          this.modelo_impressao = sessionStorage['modelo_impressao']
        }catch(e){}
        
        try{
          this.obj_exames = JSON.parse(sessionStorage['obj_exames'])
        }catch(e){}
        
        try{
          this.obj_dados_profissional = JSON.parse(sessionStorage['obj_dados_profissional'])
        }catch(e){}


        let UUF = this.obj_dados_profissional.UF
        if (UUF=='undefined'){ UUF = '' }
        
        // PREENCHE O OBJETO DE EDICAO
        this.$set(this.edit_objeto, 'NOME'    , this.obj_dados_paciente.NOME)

        this.$set(this.edit_objeto, 'MEDICO'    , this.obj_dados_profissional.NOME)
        this.$set(this.edit_objeto, 'CNES_PF' , this.obj_dados_profissional.CNES_PF)
        this.$set(this.edit_objeto, 'CRM'     , this.obj_dados_profissional.CRM)
        this.$set(this.edit_objeto, 'UF'      , UUF) 
        
        
      },

      showDrawer(){
        this.$emit('executar', false)//mostrar ou nao paineis
      },

      monta_exames(){


        let A = this.dados.EXAMES
        let B = {}
        let MAX_F = 4 //MAX FIXO
        let MAX   = MAX_F //AUMENTA QUANDO CHEGA NO MAXIMO FIXO
        let tag   = 0

        for (let i = 0; i < A.length; i++) {
          
          if (i<=MAX){
            // console.log('MAX:'+ ((MAX - MAX_F) - i *-1));
             this.$set(B, ((MAX - MAX_F) - i *-1) , A[i])
             this.$set(this.lista_exames, tag, B)

             if((MAX-i)==0){
              // console.log('zerou');
              B = {}
              tag +=1
              MAX += MAX_F + parseInt(1)
              MAX_F += MAX + parseInt(1)
              // console.log('novo max:'+MAX);
              // console.log('tag:'+tag);
             }
          }
        }

        // console.log('this.lista_exame:');
        // console.log(this.lista_exames);

        // console.log(this.lista_exames.length);
        // console.log(this.lista_exames[1]);
      },

      define_logo(convenio){

        convenio = convenio.toUpperCase()

        let src="http://dsi.app.br/logos/"

        if ( convenio.includes('PREVER')){
           src += 'prever.png'
        }
        else if ( convenio.includes('PAM')){
           src += 'pam.png'
        }
        else if ( convenio.includes('UNIMED')){
           src += 'unimed.png'
        }
        else if ( convenio.includes('SANTA CASA')){
           src += 'santacasa.png'
        }else{
          src = ''
        }

        this.path_logo = src
      },

      imprimir(){        


        this.vai_imprimir = true
        
        setTimeout(function(){

          if (this.modelo_impressao=='SADT'){
             setPrintStyle('landscape')
          }

          window.print()
          this.vai_imprimir = false
        }.bind(this), 111);//usar esse .bind(this) para chamar funcoes com o setTimeout


        // setPrintStyle('portrait')
        setPrintStyle('auto')

      },

      close(){
        window.close()
      },
    },

}

</script>

<style scoped>
.inline {
  display: inline !important;
}

.top {
  font-size: 8px;
  margin-top: 0px;
  margin-left: 1px;
}

.top- {
  font-size: 7px;
  margin-top: -1px;
  margin-left: 1px;
}

.meio {
  font-size: 8px;
  margin-top: -14px;
  margin-left: 1px;
}

.texto {
  font-size: 9px;
  margin-top: -16px;
  margin-left: 3px;
  font-weight: bold;
}

.v-cardd {
  border-radius: 0 !important;
  box-shadow: none !important;
  border: 1px ridge silver !important;
}

.v-card {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 0 !important;
}

.titulo {
  box-shadow: 0 0 0 1000px silver inset !important;
  border: none !important;
}

.sem_borda {
  border: none !important;
  box-shadow: none !important;
}

.borda {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
}

/* Adicione estas regras específicas para impressão */
@media print {
  .no-print {
    display: none !important;
  }

  .v-dialog__content.v-dialog__content--active {
    position: inherit !important;
  }

  .v-dialog--fullscreen {
    position: absolute !important;
    overflow: visible !important;
  }

  @page {
    margin-top: 1cm !important;
  }
}

/* Classes de tamanho de fonte */
.fs-7 {
  font-size: 7px !important;
}
.fs-8 {
  font-size: 8px !important;
}
.fs-9 {
  font-size: 9px !important;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.bold {
  font-weight: bold !important;
}

/* Adicionando comportamento interativo */
.hand:hover {
  cursor: pointer;
}

/* Estilos para imagem com sobreposição */
.position-relative {
  position: relative !important;
}

.overlay-content {
  position: absolute !important;
  top: 140px !important;
  left: 50px !important;
  width: 100% !important;
  z-index: 1 !important;
  /* color: black !important; */
}
</style>

